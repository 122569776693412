<template>
    <v-dialog
        v-model="dialog"
        width="420"
        overlay-opacity="0.8"
        persistent
        @click:outside="hide"
    >
        <v-card color="grey darken-3" class="pa-5" align="center">
            <v-icon class="mt-3" color="yellow darken-3" size="50">
                mdi-checkbox-marked-circle-outline
            </v-icon>
            <p class="mt-5 white--text">{{ text }}</p>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        text: { type: String, default: '' },
        delayClose: { type: Number, default: 2000 },
        autoClose: { type: Boolean, default: true }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        show(){
            this.dialog = true;
            if(this.autoClose){
                setTimeout(() => {
                    this.hide();
                }, this.delayClose);
            }
        },
        hide(){
            this.dialog = false;
            this.$emit('hide');
        }
    },
    mounted(){

    }
};
</script>