<template>
    <v-container>
        <v-breadcrumbs class="ma-0 pl-0" :items="breadcrumbs" dark>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-row>
            <v-col>
                <label class="d-block text-h6 white--text font-weight-bold mb-0">
                    หมายเลขคำสั่งซื้อ
                    <span class="yellow--text text--darken-3">#{{ job.code || '' }}
                    </span>
                    <v-chip small :color="colorStatus(status)">
                        <v-icon class="ml-n3" :color="colorStatus(status).split(' ')[0]">mdi-circle-medium</v-icon>
                        <span class="white--text">{{ getStatusThaiName(status) }}</span>
                    </v-chip>
                </label>
                <v-row no-gutters v-if="sealNumbers.length > 0">
                    <v-col cols="1">
                        <label class="yellow--text text--darken-3">หมายเลขซีล</label>
                    </v-col>
                    <v-col cols="11">
                        <label class="text-caption white--text"> {{sealNumbersText}} </label>
                    </v-col>
                </v-row>
                <label class="d-block text-caption grey--text">เวลาสั่งซื้อ : {{ createdAt }} น.</label>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="1" v-if="job.customer && job.customer.company && job.customer.company != 'undefined'">
                <label class="text-caption grey--text">บริษัท</label>
            </v-col>
            <v-col cols="11" v-if="job.customer && job.customer.company && job.customer.company != 'undefined'">
                <label class="text-caption white--text">{{ job.customer.company }} </label>
            </v-col>
            <v-col cols="1">
                <label class="text-caption grey--text">ชื่อลูกค้า</label>
            </v-col>
            <v-col cols="3">
                <label class="text-caption white--text">{{ job.customer ? job.customer.name : '' }} </label>
            </v-col>
            <v-col cols="8">
                <v-icon color="yellow darken-3" class="mr-2">
                    mdi-check-circle
                </v-icon>
                <label class="text-caption white--text">{{ paymentText }}</label>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="1">
                <label class="text-caption grey--text">เบอร์โทร</label>
            </v-col>
            <v-col cols="3">
                <label class="text-caption white--text">{{ job.customer ? job.customer.mobile : '' }}</label>
            </v-col>
            <v-col cols="8" v-if="job.ordering.paymentType == '2'">
                <v-icon color="yellow darken-3" class="mr-2">
                    mdi-check-circle
                </v-icon>
                <label class="text-caption white--text">
                    {{ job.ordering && job.ordering.paymentType == '1' || job.ordering && job.ordering.paymentType ==
                    '3'
                    ? 'ยังไม่ชำระเงิน'
                    : job.ordering && job.ordering.paymentFile == null ? 'ยังไม่ชำระเงิน' : 'ชำระเงินแล้ว'
                    }}
                </label>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="8">
                <v-card dark outlined class="pa-3 pb-8">
                    <div class="d-flex">
                        <div>
                            <p class="text-h7 font-weight-bold yellow--text text--darken-3">รายละเอียดคำสั่งซื้อ</p>
                        </div>
                        <v-spacer />
                        <div>
                            <v-btn v-if="job.ordering && job.ordering.status == 'sent'" @click="downloadSentSlip"
                                rounded outlined color="yellow darken-3">
                                <v-icon class="mr-1">
                                    mdi-printer
                                </v-icon>
                                พิมพ์ใบขนส่ง
                            </v-btn>
                            <v-btn @click="downloadPDF" rounded outlined color="yellow darken-3" class="ml-2">
                                <v-icon class="mr-1">
                                    mdi-printer
                                </v-icon>
                                พิมพ์รายการ
                            </v-btn>
                        </div>
                    </div>
                    <v-row>
                        <v-col cols="4">
                            <label class="text-body-2 white--text">
                                รายการสินค้า
                            </label>
                        </v-col>
                        <v-col cols="3" align="center">
                            <label class="text-body-2 white--text">
                                ประเภทสินค้า
                            </label>
                        </v-col>
                        <v-col cols="2" align="center">
                            <label class="text-body-2 white--text">
                                จำนวน
                            </label>
                        </v-col>
                        <v-col cols="3" align="center">
                            <label class="text-body-2 white--text">
                                ราคา
                            </label>
                        </v-col>
                    </v-row>
                    <div v-for="p in job.products" :key="p.price">
                        <v-row class="my-1">
                            <v-col cols="4">
                                <div>
                                    <v-img class="d-inline-flex" lazy-src="https://picsum.photos/id/11/10/6"
                                        src="@/assets/images/logo1.svg" max-height="84" max-width="84">
                                    </v-img>
                                    <div class="ml-4 d-inline-block">
                                        <label class="d-block font-weight-bold">{{ p.details ? p.details :
                                            '-' }}</label>
                                        <label class="d-block grey--text">ขนาด {{ p.weight.value }} {{
                                            transformWeightValueToUnit(p.weight.unit) }}</label>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="3" align="center">
                                <div class="d-inline-block">
                                    <label class="d-block font-weight-bold">{{ transformProductType(p.type)
                                        }}</label>
                                    <label class="d-block grey--text">{{ transformProductSubType(p) }}</label>
                                </div>
                            </v-col>
                            <v-col cols="2" class="font-weight-bold" align="center">
                                {{ p.quantity ? p.quantity.value : '-' }}
                            </v-col>
                            <v-col cols="3" class="font-weight-bold yellow--text text--darken-3" align="center">
                                {{ numberToLocalText(p.price) }}
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </div>
                </v-card>

                <v-card outlined dark class="pa-3 mt-5">
                    <label class="font-weight-bold yellow--text text--darken-3">สรุปยอดรายการ</label>
                    <div class="d-block">
                        <label class="text-body1 d-inline-block grey--text" style="width: 25%">น้ำหนักรวม</label>
                        <label class="text-body1 white--text">{{ totalWeight }}</label>
                    </div>
                    <!-- <div class="d-block">
                        <label class="text-body1 d-inline-block grey--text" style="width: 25%">ขนาดพัสดุ</label>
                        <label class="text-body1 white--text">{{ orderSize }}</label>
                    </div> -->
                    <div class="d-block">
                        <label class="text-body1 d-inline-block grey--text" style="width: 25%">ยอดรวม</label>
                        <label class="text-h6 white--text">{{ sumProductsPrice }} บาท</label>
                    </div>
                    <div class="d-block">
                        <label class="text-body1 d-inline-block grey--text" style="width: 25%">ค่าจัดส่ง</label>
                        <div v-if="stage == 0 && subStatus == 'request_create'" class="d-inline-block">
                            <div class="d-inline-block mr-2" style="width: 75px;">
                                <v-text-field v-model="shippingFee" color="yellow darken-3" class="d-inline-block mb-n6"
                                    dark outlined dense>
                                </v-text-field>
                            </div>
                            <label class="d-inline-block text-body1 white--text">บาท</label>
                        </div>
                        <div class="d-inline-block" v-else>
                            <label class="text-body1 white--text">{{ shippingFeeText }} บาท</label>
                            <label class="text-caption red--text text--lighten-1 ml-2">{{ subStatus == 'deal_shipping'
                                && status != 'cancel' ? '* กำลังรอลูกค้ายืนยัน' : ''}}</label>
                        </div>
                    </div>
                </v-card>

                <v-card outlined dark class="pa-3 mt-5" height="150"
                    v-if="job && job.ordering && job.ordering.subStatus != 'request_create' && job.ordering.subStatus != 'deal_shipping'">
                    <label class="font-weight-bold yellow--text text--darken-3">การชำระเงิน</label>
                    <div class="d-block mt-2">
                        <v-icon color="yellow darken-3" class="mr-2">
                            mdi-check-circle
                        </v-icon>
                        <label class="white--text"> {{ paymentText }}</label>
                    </div>
                    <v-img v-if="job && job.ordering && job.ordering.paymentType != '1' && paymentImage != null"
                        style="position: absolute; top: 0; right: 0;"
                        class="d-inline-block mb-0 rounded rounded-sm mt-5 mr-5"
                        lazy-src="https://picsum.photos/id/11/10/6" height="110" width="70" :src="paymentImage"
                        @click="showDialogImage(paymentImage)"></v-img>
                    <label v-if="job && job.ordering && job.ordering.paymentType == '3'"
                        class="mt-5 yellow--text text--darken-3">
                        <v-icon class="mr-2" color="yellow darken-3">
                            mdi-text
                        </v-icon>
                        <label class="white--text text-caption"> {{ job.ordering && job.ordering.paymentNote ?
                            job.ordering.paymentNote : '' }}</label>
                    </label>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card outlined class="pa-3" dark>
                    <v-row class="pa-3">
                        <label class="font-weight-bold yellow--text text--darken-3">ข้อมูลการจัดส่ง</label>
                    </v-row>
                    <!-- For 1 way  -->
                    <div v-if="job.ordering && job.ordering.address2 == null">
                        <v-row class="pa-3">
                            <label class="d-block font-weight-bold grey--text">ต้นทาง</label>
                            <label class="d-block white--text">
                                {{ address1Start }}
                            </label>
                        </v-row>
                        <v-row class="pa-3">
                            <label class="font-weight-bold grey--text">ปลายทาง</label>
                            <label class="d-block white--text">
                                {{ address1Destination }}
                            </label>
                        </v-row>
                    </div>


                    <!-- For 2 ways -->
                    <div v-else>
                        <v-row class="pa-3">
                            <label class="font-weight-caption yellow--text text--darken-3 mb-1"
                                style="width: 100%">ขาไป</label>
                            <label class="d-block font-weight-bold grey--text w-100">ต้นทาง</label>
                            <label class="d-block white--text">
                                {{ address1Start }}
                            </label>
                        </v-row>
                        <v-row class="pa-3">
                            <label class="font-weight-bold grey--text">ปลายทาง</label>
                            <label class="d-block white--text">
                                {{ address1Destination }}
                            </label>
                        </v-row>
                        <v-row class="pa-3">
                            <label class="font-weight-caption yellow--text text--darken-3 mb-1"
                                style="width: 100%">ขากลับ</label>
                            <label class="d-block font-weight-bold grey--text">ต้นทาง</label>
                            <label class="d-block white--text">
                                {{ address2Start }}
                            </label>
                        </v-row>
                        <v-row class="pa-3">
                            <label class="font-weight-bold grey--text">ปลายทาง</label>
                            <label class="d-block white--text">
                                {{ address2Destination }}
                            </label>
                        </v-row>
                    </div>
                    <!-- End Address -->
                    <v-row class="pa-3">
                        <v-card class="pa-3" color="black" style="width: 100%">
                            <label class="font-weight-bold grey--text">ตัวเลือกการจัดส่ง</label>
                            <label class="d-block white--text">{{ channelText }}</label>
                        </v-card>
                    </v-row>

                    <!-- Messenger -->
                    <v-row v-if="job.channel != '3'" class="pa-3">
                        <label class="white--text">เลือกรายชื่อพนักงานส่งของ</label>
                        <v-select dark v-model="messengers" :items="listActiveMessenger" filled chips multiple
                            :light="false" placeholder="กดเพื่อเลือกพนักงาน"
                            :disabled="myRole != 'sr_staff' || (-1 == ['wait_confirm', 'wait_confirm_with_approval', 'wait_messenger'].indexOf(status) || waitPickup)">
                        </v-select>
                    </v-row>

                    <v-row class="mt-5 mx-2" v-if="job.channel == '3'">
                        <v-form ref="parcelForm" style="width: 100%" @submit.prevent>
                            <v-text-field :disabled="status != 'wait_messenger'" class="mb-0" dark v-model="parcelCode"
                                color="yellow darken-3" label="หมายเลขพัสดุ" outlined :rules="requiredRules">
                            </v-text-field>
                        </v-form>
                    </v-row>

                    <!-- Status Job -->
                    <v-row class="pa-3">
                        <label class="font-weight-bold yellow--text text--darken-3">สถานะรายการ</label>
                    </v-row>

                    <div class="py-3 d-block">
                        <label class="d-block white--text">
                            <v-icon v-if="stage >= 1 && status != 'cancel'" dark color="green" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-if="stage == 0 && status != 'cancel'" dark color="grey darken-3" class="mr-2">
                                mdi-checkbox-blank-circle
                            </v-icon>
                            <v-icon v-if="status == 'cancel'" dark color="grey darken-3" class="mr-2">
                                mdi-minus-circle
                            </v-icon>
                            สร้างคำสั่งซื้อ
                        </label>
                        <label style="margin-left: 32px;" class="d-block grey--text">{{ timeStart }}</label>
                        <label v-if="stage >= 1 && nameStartCustomer" style="margin-left: 32px;"
                            class="d-block grey--text text-body-2">{{ nameStartCustomer }}</label>
                        <label v-if="stage >= 1" style="margin-left: 32px;" class="d-block grey--text text-body-2">{{
                            nameStartUser }}</label>
                    </div>
                    <div class="pt-0 pb-3 d-block">
                        <label class="d-block white--text">
                            <v-icon v-if="stage >= 2  && status != 'cancel'" dark color="green" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-if="stage < 2  && status != 'cancel'" dark color="grey darken-3" class="mr-2">
                                mdi-checkbox-blank-circle
                            </v-icon>
                            <v-icon v-if="status == 'cancel'" dark color="grey darken-3" class="mr-2">
                                {{ stage >= 2 ? 'mdi-minus-circle' : 'mdi-checkbox-blank-circle' }}
                            </v-icon>
                            อนุมัติรายการ
                        </label>
                        <label v-if="stage >= 2" style="margin-left: 32px;" class="d-block grey--text">{{ timeApprove
                            }}</label>
                        <label v-if="stage >= 2" style="margin-left: 32px;" class="d-block grey--text text-body-2">{{
                            nameApprove }}</label>
                    </div>
                    <div class="pt-0 pb-3 d-block">
                        <label class="d-block white--text">
                            <v-icon v-if="stage >= 3" dark color="green" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-if="stage < 3" dark color="grey darken-3" class="mr-2">
                                mdi-checkbox-blank-circle
                            </v-icon>
                            {{ job.channel == '3' ? 'กรอกหมายเลขพัสดุ' : 'แมสสแกนรับพัสดุแล้ว' }}
                        </label>
                        <label v-if="stage >= 3" style="margin-left: 32px;" class="d-block grey--text">{{ timeSending
                            }}</label>
                        <label v-if="stage >= 3" style="margin-left: 32px;" class="d-block grey--text text-body-2">{{
                            nameSending }}</label>
                    </div>
                    <div class="pt-0 pb-3 d-block">
                        <label class="d-block white--text">
                            <v-icon v-if="stage >= 3" dark color="green" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-if="stage < 3" dark color="grey darken-3" class="mr-2">
                                mdi-checkbox-blank-circle
                            </v-icon>
                            กำลังจัดส่ง
                        </label>
                        <label v-if="stage >= 3" style="margin-left: 32px;" class="d-block grey--text">{{ timeSending
                            }}</label>
                        <label v-if="stage >= 3" style="margin-left: 32px;" class="d-block grey--text text-body-2">{{
                            nameSending }}</label>
                    </div>
                    <div class="pt-0 pb-3 d-block">
                        <label class="d-block white--text">
                            <v-icon v-if="stage >= 4" dark color="green" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-if="stage < 4" dark color="grey darken-3" class="mr-2">
                                mdi-checkbox-blank-circle
                            </v-icon>
                            จัดส่งสำเร็จ
                        </label>
                        <label v-if="stage >= 4" style="margin-left: 32px;" class="d-block grey--text">{{ timeSent
                            }}</label>
                        <label v-if="stage >= 4" style="margin-left: 32px;" class="d-block grey--text text-body-2">{{
                            nameSent }}</label>
                    </div>
                    <div v-if="'cancel' == status" class="pt-0 pb-3 d-block">
                        <label class="d-block white--text">
                            <v-icon dark color="red" class="mr-2">
                                mdi-check-circle
                            </v-icon>
                            ยกเลิก
                        </label>
                        <label style="margin-left: 32px;" class="d-block grey--text">{{ timeCancel }}</label>
                        <label style="margin-left: 32px;" class="d-block grey--text text-body-2">{{ nameCancel
                            }}</label>
                        <label style="margin-left: 32px;" class="d-block red--text text--lighten-2 text-body-2">เหตุผล :
                            {{ rejectText }}</label>
                    </div>
                    <div class="pt-2 pb-3 d-block" v-if="status == 'sent' && job.channel != '3'">
                        <label class="white--text d-block">หลักฐานการจัดส่ง :
                            <span v-if="job.ordering && job.ordering.address2 != null"
                                class="font-weight-bold yellow--text text--darken-3">ขาไป</span>
                        </label>
                        <div class="d-flex justify-space-around">
                            <v-img class="d-inline-flex mt-3 rounded-lg" lazy-src="https://picsum.photos/id/11/10/6"
                                :src="sentPhoto" @click="showDialogImage(sentPhoto)" max-height="120" max-width="120">
                            </v-img>
                            <v-img class="d-inline-flex mt-3 rounded-lg" lazy-src="https://picsum.photos/id/11/10/6"
                                :src="sentSignature" @click="showDialogImage(sentSignature)" max-height="120"
                                max-width="120">
                            </v-img>
                        </div>
                        <label v-if="job.ordering && job.ordering.address2 != null"
                            class="white--text d-block mt-3">หลักฐานการจัดส่ง :
                            <span class="font-weight-bold yellow--text text--darken-3">ขากลับ</span>
                        </label>
                        <div v-if="job.ordering && job.ordering.address2 != null" class="d-flex justify-space-around">
                            <v-img class="d-inline-flex mt-3 rounded-lg" lazy-src="https://picsum.photos/id/11/10/6"
                                :src="sentPhoto2" @click="showDialogImage(sentPhoto2)" max-height="120" max-width="120">
                            </v-img>
                            <v-img class="d-inline-flex mt-3 rounded-lg" lazy-src="https://picsum.photos/id/11/10/6"
                                :src="sentSignature2" @click="showDialogImage(sentSignature2)" max-height="120"
                                max-width="120">
                            </v-img>
                        </div>
                    </div>
                    <div class="pt-2 pb-3 d-block" v-if="job.ordering && job.ordering.status == 'sent'">
                        <label class="white--text d-block">
                            หมายเหตุ :
                        </label>
                        <label class="grey--text text-body-2 d-block">
                            {{ job.ordering.sentNote || '' }}
                        </label>
                    </div>
                </v-card>

                <!-- Button Approve Zone -->
                <!-- Staff -->
                <div class="my-8"
                    v-if="(status == 'new' || status == 'before_new' || status == 'wait_messenger' || status == 'sending' || status == 'wait_confirm') && myRole == 'staff'">
                    <v-row class="px-3" justify="space-around">
                        <v-btn v-if="status == 'new' || status == 'before_new' || status == 'wait_confirm'"
                            color="yellow darken-3" outlined @click="$refs.dialogReject.show($route.params.id)">
                            ยกเลิกรายการ</v-btn>
                        <v-btn v-if="status == 'new'" color="yellow darken-3"
                            @click="$refs.dialogConfirmRequestApprove.show()">
                            <span class="black--text">
                                ส่งคำขออนุมัติ
                            </span>
                        </v-btn>
                        <v-btn v-if="stage == 0 && subStatus == 'request_create'" color="yellow darken-3"
                            @click="$refs.dialogConfirmShipping.show()">
                            <span class="black--text">
                                ตกลงค่าขนส่ง
                            </span>
                        </v-btn>
                        <v-btn v-if="stage == 0 && subStatus == 'accepted'" color="yellow darken-3"
                            @click="$refs.dialogConfirmCreate.show()">
                            <span class="black--text">
                                อนุมัติการสร้าง
                            </span>
                        </v-btn>
                        <v-btn v-if="stage == 2 && status == 'wait_messenger' && job.channel == '3'"
                            color="yellow darken-3" @click="$refs.dialogConfirmSaveParcelCode.show()">
                            <span class="black--text">
                                บันทึกหมายเลขพัสดุ
                            </span>
                        </v-btn>
                        <v-btn v-if="status == 'sending' && job.channel == '3'" color="yellow darken-3"
                            @click="$refs.dialogSaveJobDone.show()">
                            <span class="black--text">
                                ปิดงาน
                            </span>
                        </v-btn>
                    </v-row>
                </div>
                <!-- Senior Staff -->
                <div class="my-8"
                    v-if="myRole == 'sr_staff' && -1 != ['wait_confirm', 'wait_confirm_with_approval', 'wait_messenger'].indexOf(status)">
                    <v-row class="px-3" justify="space-around">
                        <v-btn color="yellow darken-3" outlined @click="$refs.dialogReject.show($route.params.id)">
                            {{ 'ไม่อนุมัติ' }}
                        </v-btn>
                        <v-btn v-if="job.channel != '3' && !waitPickup" :disabled="messengers.length == 0" dark
                            class="px-5" color="yellow darken-3" @click="sendApprove">
                            <span :class="messengers.length == 0 ? 'grey--text' : 'black--text'">
                                {{ status == 'wait_messenger' ? 'บันทึกข้อมูล' : 'อนุมัติ' }}
                            </span>
                        </v-btn>
                        <v-btn v-if="job.channel == '3' && status != 'wait_messenger'" dark class="px-5"
                            color="yellow darken-3" @click="sendApprove">
                            <span class="black--text">
                                อนุมัติ
                            </span>
                        </v-btn>
                    </v-row>
                </div>
                <!-- Approval -->
                <div class="my-8" v-if="status == 'wait_confirm_with_approval' && myRole == 'approval'">
                    <v-row class="px-3" justify="space-around">
                        <v-btn color="yellow darken-3" outlined @click="$refs.dialogReject.show($route.params.id)">
                            ไม่อนุมัติ</v-btn>
                        <v-btn color="yellow darken-3" @click="$refs.dialogConfirmApproveByApproval.show()">
                            <span class="black--text">
                                อนุมัติ
                            </span>
                        </v-btn>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
        <dialog-success :text="'ส่งคำขออนุมัติสำเร็จ'" @hide="hideSuccess" ref="dialogSuccess" />
        <dialog-success :text="'อนุมัติสำเร็จ'" @hide="hideSuccess" ref="dialogSuccessApprove" />
        <dialog-success :text="'บันทึกสำเร็จ'" @hide="hideSuccess" ref="dialogSuccessShipping" />
        <dialog-success :text="'สร้างคำสั่งซื้อสำเร็จ'" @hide="hideSuccess" ref="dialogSuccessConfirmCreate" />
        <dialog-success :text="'บันทึกหมายเลขพัสดุสำเร็จ'" @hide="hideSuccess" ref="dialogSuccessSaveParcelCode" />
        <dialog-confirm :title="'ยืนยันส่งคำขอ'" :description="'โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนกดยืนยัน'"
            @confirm="sendRequestApprove" ref="dialogConfirmRequestApprove" />
        <dialog-confirm :title="'ยืนยันค่าขนส่ง'"
            :description="`โปรดตรวจสอบข้อมูลค่าขนส่ง ${shippingFee} บาท ถูกต้องหรือไม่ก่อนกดยืนยัน`"
            @confirm="sendShippingConfirm" ref="dialogConfirmShipping" />
        <dialog-reject @finishReject="finishReject" ref="dialogReject" />
        <dialog-image ref="dialogImage" />
        <dialog-confirm :title="'ยืนยันการส่งอนุมัติ'" :description="'โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนกดยืนยัน'"
            @confirm="sendApproveByApproval" ref="dialogConfirmApproveByApproval" />
        <dialog-confirm :title="'ยืนยันอนุมัติการสร้าง'"
            :description="'ยืนยันการสร้างเพื่ออัพเดรตสถานะคำสั่งซื้อเป็น มาใหม่'" @confirm="sendConfirmCreate"
            ref="dialogConfirmCreate" />
        <dialog-confirm :title="'ยืนยันบันทึกหมายเลขพัสดุ'" :description="'ตรวจสอบหมายเลขพัสดุก่อนกดยืนยัน'"
            @confirm="saveParcelCode" ref="dialogConfirmSaveParcelCode" />
        <dialog-confirm :title="'ยินยันปิดงาน'" :description="'ปิดงานเพื่อเปลี่ยนสถานะของงานเป็นจัดส่งแล้ว'"
            @confirm="saveJobDone" ref="dialogSaveJobDone" />
    </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getJobById, getChannelName, getStatusThaiName, updateShippingCustomer, colorStatus } from '../../models/job';
import moment from 'moment';
import axios from 'axios';
import AlertError from '../../components/AlertError.vue';
import jsonwebtoken from 'jsonwebtoken';
import DialogSuccess from '../../components/DialogSuccess.vue';
import DialogConfirm from '../../components/DialogConfirm.vue';
import DialogReject from '../../components/DialogReject.vue';
import DialogImage from '../../components/DialogImage.vue';
import fd from 'js-file-download';
import { requiredRules } from '../../utils/validation-rule';
import Text from '../../utils/text-mapping';

export default {
    data: () => ({
        breadcrumbs: [
            {
                text: 'หน้าหลัก',
                disabled: false,
                href: '/'
            },
            {
                text: 'รายละเอียดคำสั่งซื้อ',
                disabled: true,
                href: ''
            }
        ],
        job: {},
        paymentImage: null,
        errorMessage: '',
        timeStart: null,
        nameStartCustomer: null,
        nameStartUser: null,
        timeApprove: null,
        nameApprove: null,
        timeSending: null,
        nameSending: null,
        timeSent: null,
        nameSent: null,
        timeCancel: null,
        nameCancel: null,
        stage: 0, // use for rending 0 = none, 1 = start, 2 = approve, 3 = pickup & sending, 4 = sent
        status: 'new',
        myRole: null,
        messengers: [],
        listActiveMessenger: [],
        sentPhoto: null,
        sentSignature: null,
        sentPhoto2: null,
        sentSignature2: null,
        shippingFee: 0,
        subStatus: '',
        parcelCode: '',
        requiredRules,
        waitPickup: false,
        rejectText: '',
        sealNumbers: []
    }),
    methods: {
        numberToLocalText (number) {
            return Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        transformWeightValueToUnit (value) {
            if ('g' == value) {
                return 'กรัม';
            } else if ('kg' == value) {
                return 'กิโลกรัม';
            } else if ('b' == value) {
                return 'บาท';
            } else {
                return value;
            }
        },
        formatTime (text) {
            return text ? moment(text).format('DD-MM-YYYY H.mm') + ' น.' : '';
        },
        hideSuccess () {
            location.reload();
        },
        colorStatus,
        getStatusThaiName (value) {
            return getStatusThaiName(value);
        },
        async sendRequestApprove () {
            const id = this.$route.params.id;
            let loader = this.$loading.show();
            try {
                await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${id}/request-approve`);
                loader.hide();
                this.$refs.dialogSuccess.show();
            } catch (error) {
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async fetchMessenger () {
            let loader = this.$loading.show();
            try {
                this.listActiveMessenger = [];
                const response2 = await axios.get(process.env.VUE_APP_BASE_URL + '/ordering/messenger-associated/' + this.$route.params.id);
                this.messengers = response2.data.assignMessenger.map(u => ({ text: `${u.name} - ${(u.id + '').padStart(5, '0')}`, value: u.id }));
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/ordering/messenger?workingStatus=active,busy,offline');
                this.listActiveMessenger = response.data.users.map(u => ({ text: `${u.name} - ${(u.id + '').padStart(5, '0')}`, value: u.id }));
                this.messengers.forEach(m => {
                    const have = this.listActiveMessenger.find(a => m.value == a.value);
                    if (!have) {
                        this.listActiveMessenger.push(m);
                    }
                });
            } catch (error) {
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async sendApprove () {
            const id = this.$route.params.id;
            let loader = this.$loading.show();
            try {
                if ('3' == this.job.channel) {
                    await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${id}/approve`);
                } else {
                    if ('wait_messenger' == this.status) { // update
                        await axios.patch(process.env.VUE_APP_BASE_URL + `/ordering/jobs/${id}/messenger`, { assign: this.messengers });
                    } else { // new
                        await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${id}/approve`, { assign: this.messengers });
                    }
                }
                loader.hide();
                this.$refs.dialogSuccessApprove.show();
            } catch (error) {
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async finishReject () {
            this.$router.replace({ name: 'JobsList' });
        },
        downloadPDF () {
            const id = this.$route.params.id;
            axios.post(process.env.VUE_APP_BASE_URL + '/reports/purchase-order', { jobs: [id] }, { responseType: 'blob' })
                .then(response => {
                    fd(response.data, 'purchase.pdf', response.headers['content-type']);
                });
        },
        downloadSentSlip () {
            const id = this.$route.params.id;
            axios.post(process.env.VUE_APP_BASE_URL + '/reports/slip/' + id, {}, { responseType: 'blob' })
                .then(response => {
                    fd(response.data, `slip_${this.job.code}.pdf`, response.headers['content-type']);
                });
        },
        showDialogImage (image) {
            if (image) {
                this.$refs.dialogImage.show(image);
            }
        },
        async sendApproveByApproval () {
            let loader = this.$loading.show();
            try {
                await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${this.$route.params.id}/approve-by-approval`);
                loader.hide();
                this.$refs.dialogSuccessApprove.show();
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async sendShippingConfirm () {
            let loader = this.$loading.show();
            try {
                await updateShippingCustomer(this.job, this.shippingFee);
                loader.hide();
                this.$refs.dialogSuccessShipping.show();
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async sendConfirmCreate () {
            let loader = this.$loading.show();
            try {
                await axios.post(process.env.VUE_APP_BASE_URL + '/jobs/accept-create/' + this.job.id);
                loader.hide();
                this.$refs.dialogSuccessConfirmCreate.show();
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async saveParcelCode () {
            this.$refs.parcelForm.validate();
            if ('' == this.parcelCode) {
                return;
            }
            let loader = this.$loading.show();
            try {
                const data = { parcelCode: this.parcelCode };
                await axios.post(process.env.VUE_APP_BASE_URL + '/jobs/' + this.job.id + '/parcel', data);
                loader.hide();
                this.$refs.dialogSuccessSaveParcelCode.show();
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        async saveJobDone () {
            let loader = this.$loading.show();
            try {
                await axios.post(process.env.VUE_APP_BASE_URL + '/jobs/' + this.job.id + '/done');
                loader.hide();
                this.$refs.dialogSuccessSaveParcelCode.show();
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        },
        transformProductType (type) {
            return Text.productTypeToText(type);
        },
        transformProductSubType (product) {
            return Text.subProductTypeToText(product);
        }
    },
    computed: {
        createdAt(){ // format  02-04-2021 12.22
            return this.job?.ordering?.createdAt ? moment(this.job.ordering.createdAt).format('DD-MM-YYYY H.mm') : '';
        },
        totalWeight(){
            const unit = this.job?.ordering?.sumWeight?.unit || '';
            const value = this.job?.ordering?.sumWeight?.value || '';
            let unitText = '';
            if('g' == unit){
                unitText = 'กรัม';
            }else if('kg' == unit){
                unitText = 'กิโลกรัม';
            }else {
                unitText = '';
            }
            return `${value} ${unitText}`;
        },
        orderSize(){
            const width = this.job?.ordering?.width || '';
            const length = this.job?.ordering?.length || '';
            const height = this.job?.ordering?.height || '';
            return `${width} x ${length} x ${height} cm.`;
        },
        shippingFeeText(){
            const fee = this.job && this.job.ordering ? this.job.ordering.shippingFee + '' : '0';
            return fee.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '';
        },
        sumProductsPrice(){
            const products = this.job?.products || [];
            let price = 0;
            products.forEach(p => {
                price += Number(p.price);
            });
            // Not include fee price
            // const fee = this.shippingFee ? this.shippingFee + '' : '0';
            // price += Number(fee);
            return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        paymentText(){
            const type = this.job?.ordering?.paymentType || '';
            if('1' == type){
                return 'เก็บเงินปลายทาง';
            }else if('2' == type){
                return 'โอนเงินผ่านธนาคาร หรือ Mobile Banking';
            }else if('3' == type){
                return 'เครดิต';
            }else{
                return '';
            }
        },
        address1Start(){
            if(!this.job.ordering){
                return '';
            }
            const address = this.job.ordering.address1.start;
            return `${address.address2} ${address.address1}`;
        },
        address1Destination(){
            if(!this.job.ordering){
                return '';
            }
            const address = this.job.ordering.address1.destination;
            return `${address.address2} ${address.address1}`;
        },
        address2Start(){
            if(!this.job.ordering || !this.job.ordering.address2){
                return '';
            }
            const address = this.job.ordering.address2.start;
            return `${address.address2} ${address.address1}`;
        },
        address2Destination(){
            if(!this.job.ordering || !this.job.ordering.address2){
                return '';
            }
            const address = this.job.ordering.address2.destination;
            return `${address.address2} ${address.address1}`;
        },
        channelText(){
            return this.job.channel ? getChannelName(this.job.channel) : '';
        },
        sealNumbersText () {
            return this.sealNumbers.map(s => s.number).join(', ');
        }
    },
    async mounted(){
        const id = this.$route.params.id;
        let loader = this.$loading.show();
        try{
            await this.fetchMessenger();
            this.job = await getJobById(id);
            this.shippingFee = this.job.ordering.shippingFee;
            this.subStatus = this.job.ordering.subStatus;
            this.parcelCode = this.job.ordering.parcelCode;
            if(-1 != ['2'].indexOf(this.job.ordering.paymentType)){
                // get image
                if(this.job.ordering.paymentFile){
                    axios.post(this.job.ordering.paymentFile.url, {}, { responseType: 'blob' })
                    .then(response => {
                        this.paymentImage = window.URL.createObjectURL(response.data);
                    });
                }else{
                    this.paymentImage = null;
                }
                
            }
            this.status = this.job.ordering.status;
            if('wait_messenger' == this.status && this.job.channel != '3'){
                // check a messenger is accepted
                const res = await axios.get(process.env.VUE_APP_BASE_URL + '/jobs/' + this.job.id + '/messenger-accepted');
                this.waitPickup = res.data.waitPickup;
            }
            if(this.job.ordering.sentPhoto){
                axios.get(this.job.ordering.sentPhoto.url, { responseType: 'blob' })
                    .then(response => {
                        this.sentPhoto = window.URL.createObjectURL(response.data);
                    });
            }
            if(this.job.ordering.sentSignature){
                axios.get(this.job.ordering.sentSignature.url, { responseType: 'blob' })
                    .then(response => {
                        this.sentSignature = window.URL.createObjectURL(response.data);
                    });
            }
            if(this.job.ordering.sentPhoto2){
                axios.get(this.job.ordering.sentPhoto2.url, { responseType: 'blob' })
                    .then(response => {
                        this.sentPhoto2 = window.URL.createObjectURL(response.data);
                    });
            }
            if(this.job.ordering.sentSignature2){
                axios.get(this.job.ordering.sentSignature2.url, { responseType: 'blob' })
                    .then(response => {
                        this.sentSignature2 = window.URL.createObjectURL(response.data);
                    });
            }

            // get activities
            const response = await axios.get(process.env.VUE_APP_BASE_URL + '/history/stage/' + id);
            const activities = response.data.activities;
            const reason = response.data.reason;
            this.stage = response.data.stage;

            for(let i = 1; i <= this.stage; i++){
                if(1 == i){
                    const activity = activities.find(a => 'create' == a.stage);
                    this.timeStart = this.formatTime(activity.time);
                    const user = activity.byUser;
                    const customer = activity.byCustomer;
                    if(customer){
                        this.nameStartCustomer = `${customer.name} (customer)`;
                    }
                    this.nameStartUser = user ? `${user.name} (staff: ${user.customerId})` : '';
                }else if(2 == i){
                    const activity = activities.find(a => 'approve' == a.stage);
                    this.timeApprove = this.formatTime(activity.time);
                    const user = activity.by;
                    this.nameApprove = `${user.name}: ${user.customerId}`;
                }else if(3 == i){
                    const activity = activities.find(a => 'sending' == a.stage);
                    this.timeSending = this.formatTime(activity.time);
                    const user = activity.by;
                    this.nameSending = `${user.name}: ${user.customerId}`;
                }else if(4 == i){
                    const activity = activities.find(a => 'sent' == a.stage);
                    this.timeSent = this.formatTime(activity.time);
                    const user = activity.by;
                    this.nameSent =  `${user.name}: ${user.customerId}`;
                }
            }
            const cancelActivity = activities.find(a => 'cancel' == a.stage);
            if(cancelActivity){
                this.timeCancel = this.formatTime(cancelActivity.time);
                const user = cancelActivity.by;
                this.nameCancel = `${user.name} (${user.role ? 'staff: ' + user.customerId : 'customer'})`;
                console.log(activities);
                this.rejectText = Text.reasonText(reason);
            }

            // setting permission
            const jwt = localStorage.getItem('jwt');
            const decoded = jsonwebtoken.decode(jwt);
            this.myRole = decoded.role;

            // get seal number
            const sealResponse = await axios.get(process.env.VUE_APP_BASE_URL + '/ordering/seal-numbers/job/' + id);
            this.sealNumbers = sealResponse.data.sealNumbers;
        }catch(error){
            console.log(error);
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.alertError.show();
        }finally{
            loader.hide();
        }
    },
    components: {
        AlertError,
        DialogSuccess,
        DialogConfirm,
        DialogReject,
        DialogImage
    }
};
</script>