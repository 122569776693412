<template>
    <v-dialog
    v-model="dialog"
    persistent
    max-width="420"
    >
        <v-card dark class="pa-3">
            <v-card-text style="text-align: center;">
                <v-row>
                    <v-col>
                        <v-icon color="yellow darken-3" size="32" class="mt-2">
                            mdi-cancel
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="mt-n3">
                        <p class="font-weight-bold">ไม่อนุมัติรายการ</p>
                        <p>กรุณาระบุเหตุผลในการไม่อนุมัติ</p>
                        <v-form v-model="isValid" ref="form">
                            <v-select
                            style="font-size: 14px;"
                            :items="items"
                            label="เลือกเหตุผล"
                            dense
                            v-model="reject.type"
                            :rules="rules"
                            :light="false"
                            ></v-select>
                            <v-textarea
                            v-model="reject.note"
                            dense
                            outlined
                            full-width
                            label="เพิ่มเติม"
                            no-resize
                            value=""
                            ></v-textarea>
                        </v-form>
                        <v-btn
                        color="yellow darken-3"
                        outlined
                        class="mr-2"
                        @click="hide"
                        width="160"
                        >
                            ยกเลิกรายการ
                        </v-btn>
                        <v-btn
                        color="yellow darken-3"
                        @click="confirm"
                        width="160"
                        >
                            <span class="black--text">
                                ยืนยัน
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-alert
                    class="mt-1 mb-0"
                    text
                    type="error"
                    v-if="errorMessage != ''">
                    {{errorMessage}}
                </v-alert>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
    data: () => ({
        dialog: false,
        items: [
            { text: 'ข้อมูลผิดพลาด', value: '4' },
            { text: 'อื่นๆ', value: '5' }
        ],
        reject: {
            id: null,
            type: null,
            note: ''
        },
        rules: [
            v => !!v || 'โปรดใส่เหตุผลในการระงับการใช้งาน'
        ],
        isValid: false,
        errorMessage: ''
    }),
    methods: {
        show(id){
            this.reject.id = id;
            this.dialog = true;
            this.isValid = false;
        },
        async confirm(){
            this.$refs.form.validate();
            if(this.isValid){  
                this.errorMessage = '';
                const loader = this.$loading.show();
                try{
                    await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${this.reject.id}/reject`, this.reject);
                    this.dialog = false;
                    this.$emit('finishReject');
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                }finally{
                    loader.hide();
                }

                
            }
        },
        hide(){
            this.reject = {
                id: null,
                type: null,
                note: ''
            };
            this.$refs.form.resetValidation();
            this.dialog = false;
            this.errorMessage = '';
        }
    },
    mounted(){

    }
};
</script>