var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('search-bar',{attrs:{"selected":_vm.selected,"downloadFuntion":_vm.downloadCSV},on:{"calendarChange":_vm.fetchFilter}},[_c('label',{staticClass:"white--text"},[_vm._v(" คำสั่งซื้อรออนุมัติ ")])]),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%","border":"0.5px solid #aaaaaa"},attrs:{"dark":"","headers":_vm.headers,"items-per-page":30,"items":_vm.data,"loading":_vm.isLoading,"loading-text":"กำลังโหลดข้อมูล....... กรูณารอซักครู่","show-select":""},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',{staticClass:"text-h6 ma-2"},[_vm._v("คำสั่งซื้อทั้งหมด "),_c('span',{staticClass:"yellow--text text--darken-3"},[_vm._v(" "+_vm._s(String(_vm.data.length).replace(/\B(?=(\d{3})+(?!\d))/g, ','))+" รายการ ")])])]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimeTable(item.createdAt))+" ")]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getChannelName(item.channel))+" ")]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_c('label',{staticClass:"yellow--text text--darken-3"},[_vm._v(" "+_vm._s(Number(item.totalPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.colorStatus(item.status)}},[_c('v-icon',{staticClass:"ml-n3",attrs:{"color":_vm.colorStatus(item.status).split(' ')[0]}},[_vm._v("mdi-circle-medium")]),_c('span',{},[_vm._v(_vm._s(_vm.getStatusThaiName(item.status)))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"yellow darken-3","depressed":"","outlined":""}},[_c('span',{staticClass:"white--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.dialogReject.show(item.id)}}},[_vm._v(" ไม่อนุมัติ ")])]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"yellow darken-3","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.dialogConfirmApproval.show(item.id)}}},[_c('span',{staticClass:"black--text"},[_vm._v(" อนุมัติ ")])])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('dialog-confirm',{ref:"dialogConfirmApproval",attrs:{"title":'ยืนยันการส่งอนุมัติ',"description":'โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนกดยืนยัน'},on:{"confirm":_vm.sendApprove}}),_c('alert-error',{ref:"alertError",attrs:{"text":_vm.errorMessage}}),_c('dialog-success',{ref:"dialogSuccessApprove",attrs:{"text":'ส่งคำขออนุมัติสำเร็จ'},on:{"hide":_vm.hideSuccessApproval}}),_c('dialog-reject',{ref:"dialogReject",on:{"finishReject":_vm.finishReject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }