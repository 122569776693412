<template>
    <v-container>
        <search-bar 
            :selected="selected"
            :downloadFuntion='downloadCSV'
            @calendarChange="fetchFilter">
            <label class="white--text">
                คำสั่งซื้อรออนุมัติ
            </label>
        </search-bar>
        <v-data-table
          dark
          v-model="selected"
          :headers="headers"
          :items-per-page="30"
          :items="data"
          :loading="isLoading"
          loading-text="กำลังโหลดข้อมูล....... กรูณารอซักครู่"
          class="elevation-1"
          style="width: 100%;border: 0.5px solid #aaaaaa;"
          show-select
          @click:row="clickRow"
          >
            <template v-slot:top>
                <p class="text-h6 ma-2">คำสั่งซื้อทั้งหมด  
                <span class="yellow--text text--darken-3"> {{ String(data.length).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} รายการ </span> </p>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatTimeTable(item.createdAt) }}
            </template>
            <template v-slot:[`item.channel`]="{ item }">
                {{ getChannelName(item.channel) }}
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <label class="yellow--text text--darken-3"> 
                    {{ Number(item.totalPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </label>
            </template>
            <template v-slot:[`item.status`]="{item}">
                <v-chip small :color="colorStatus(item.status)">
                    <v-icon class="ml-n3" :color="colorStatus(item.status).split(' ')[0]">mdi-circle-medium</v-icon>
                    <span class="">{{ getStatusThaiName(item.status) }}</span>
                </v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-container class="text-right">
                <!-- Approval Action -->
                <v-btn
                    color="yellow darken-3"
                    depressed
                    outlined
                >
                <span class="white--text" @click.stop.prevent="$refs.dialogReject.show(item.id)">
                    ไม่อนุมัติ
                </span>
                </v-btn>
                <v-btn
                    class="ml-3"
                    color="yellow darken-3"
                    depressed
                    @click.stop.prevent="$refs.dialogConfirmApproval.show(item.id)"
                >
                <span class="black--text">
                    อนุมัติ
                </span>
                </v-btn>
                </v-container>
            </template>
        </v-data-table>
        <dialog-confirm 
        :title="'ยืนยันการส่งอนุมัติ'" 
        :description="'โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนกดยืนยัน'" 
        @confirm="sendApprove"
        ref="dialogConfirmApproval"/>
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
        <dialog-success :text="'ส่งคำขออนุมัติสำเร็จ'" @hide="hideSuccessApproval" ref="dialogSuccessApprove" />
        <dialog-reject @finishReject="finishReject" ref="dialogReject" />
    </v-container>
</template>

<script>
import SearchBar from '../../components/SearchBar.vue';
import { getJobsList, getChannelName, getStatusThaiName, downloadCSV, colorStatus } from '../../models/job';
import moment from 'moment';
import DialogConfirm from '../../components/DialogConfirm.vue';
import DialogSuccess from '../../components/DialogSuccess.vue';
import DialogReject from '../../components/DialogReject.vue';
import AlertError from '../../components/AlertError.vue';
import axios from 'axios';

export default {
    data: ()=>({
        menu: false,
        selected: [],
        headers: [
            { text: 'หมายเลขคำสั่งซื้อ', value: 'code', sortable: false },
            { text: 'สร้างวันที่', value: 'createdAt' },
            { text: 'ชื่อลูกค้า', value: 'customerName', sortable: false },
            { text: 'มูลค่าสินค้า', value: 'totalPrice' },
            { text: 'สถานะรายการ', value: 'status' },
            { text: 'ช่องทาง', value: 'channel' },
            { text: '', value: 'action', sortable: false }
        ],
        data:[],
        isLoading: false,
        errorMessage: ''
    }),
    methods: {
        async fetchFilter(value){
            this.$router.push({ 
                name: 'ApprovalList', 
                query : { 
                    textSearch: value.text,
                    startDate: value.start || '', 
                    endDate: value.end || '' } 
            }).catch(() => {});
            this.isLoading = true;
            this.data = [];
            try{
                const response = await getJobsList(true, value.text, value.start || '', value.end || '', 'wait_confirm_with_approval');
                const jobs = response.data.jobs;
                jobs.forEach(j => {
                    this.data.push(j);
                });
            }catch(error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                this.isLoading = false;
            }
        },
        getChannelName(value) {
            return getChannelName(value);
        },
        formatTimeTable(date){
            return moment(date).format('DD-MM-YY HH:mm');
        },
        colorStatus,
        getStatusThaiName(value){
            return getStatusThaiName(value);
        },
        async sendApprove(id){
            let loader = this.$loading.show();
            try{
                await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${id}/approve-by-approval`);
                loader.hide();
                this.$refs.dialogSuccessApprove.show();
            }catch(error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        hideSuccessApproval(){
            this.fetchFilter({ 
                text: this.$route.query.textSearch || '', 
                start: this.$route.query.startDate || null, 
                end: this.$route.query.endDate || null });
        },
        finishReject(){
            location.reload();
        },
        clickRow(e){
            this.$router.push({ name: 'JobsDetails', params: { id: e.id } });
        },
        downloadCSV: downloadCSV
    },
    mounted(){
        this.fetchFilter({ 
            text: this.$route.query.textSearch || '', 
            start: this.$route.query.startDate || null, 
            end: this.$route.query.endDate || null });
    },
    components: {
        SearchBar,
        DialogConfirm,
        AlertError,
        DialogSuccess,
        DialogReject
    }
};
</script>

<style scoped>
    .input{
        width: 200px
    }
</style>