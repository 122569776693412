<template>
    <v-container>
        <!-- TOP Panel -->
        <p class="text-h5 mt-2">
            <slot></slot>
        </p>
        <div class="d-flex white--text">
            <div class="pa-2 mr-auto">
                <v-text-field
                    dark
                    dense
                    label="ค้นหารายการ"
                    solo
                    prepend-inner-icon="mdi-magnify"
                    @keyup.enter="filterDateRange"
                    v-model="textSearch"
                >
                </v-text-field>
            </div>
            
            <div class="pa-5">
                <label>วันที่ทำการสั่งซื้อ</label>
            </div>
            <div class="pa-0 ma-0">
                <v-menu
                    dark
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :return-value.sync="dates"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dark
                            v-model="datesText"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        no-title
                        scrollable
                        range
                        color="grey darken-1"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="grey"
                            @click="menu = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="filterDateRange"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>                
            </div>
            <div class="pa-3">
                <v-btn dark :disabled="selected.length == 0" outlined color="yellow darken-3" @click="download">
                <v-icon
                    right
                    color="yellow darken-3"
                    class="mr-2"
                >
                    mdi-cloud-download
                </v-icon>
                <span class="white--text">
                    ดาวน์โหลด
                </span>
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        selected: {
            type: Array,
            default: () => ([])
        },
        downloadFuntion: {
            type: Function,
            default: () => {}
        }
    },
    data: ()=>({
        dateFormatApi: 'DD-MM-YYYY',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        dates: [],
        datesText: '',
        startDate: null,
        endDate: null,
        textSearch: ''
    }),
    methods: {
        filterDateRange(){
            this.$refs.menu.save(this.dates);
            this.$emit('calendarChange', { text: this.textSearch, start: this.startDate, end: this.endDate });
        },
        download(){
            this.downloadFuntion(this.selected);
        }
    },
    watch: {
        dates(){
            if(1 == this.dates.length){
                this.datesText = moment(this.dates[0]).format('DD/MM/YYYY');
                this.startDate = null;
                this.endDate = null;
            }else if(2 == this.dates.length){
                const d1 = moment(this.dates[0]);
                const d2 = moment(this.dates[1]);
                if(0 < d2.diff(d1)){
                    this.datesText = d1.format('DD/MM/YYYY') + ' - ' + d2.format('DD/MM/YYYY');
                    this.startDate = d1.format(this.dateFormatApi);
                    this.endDate = d2.format(this.dateFormatApi);
                }else{
                    this.datesText = d2.format('DD/MM/YYYY') + ' - ' + d1.format('DD/MM/YYYY');
                    this.startDate = d2.format(this.dateFormatApi);
                    this.endDate = d1.format(this.dateFormatApi);
                }
            }
        },
        selected(){
            console.log(this.selected);
        }
    }
};
</script>
